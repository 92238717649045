<template>
	<div class="table-contents">
		<table width='709px'>
			<tr>
				<td rowspan="3" style="width: 124px;height: 100px;">
					{{getStatuText(msg.level)}}
					<span>{{'（'+msg.min_value+'-'+msg.max_value+'分）'}}</span>
				</td>
				<td style="width: 587px;height: 34px;">
					<input type="text" placeholder="请输入文本" maxlength="50" v-model="tabelText.text1" @input="inputTextClick" />
				</td>
			</tr>
			<tr>
				<td style="width: 587px;height: 34px;">
					<input type="text" placeholder="请输入文本" maxlength="50" v-model="tabelText.text2" @input="inputTextClick" />
				</td>
			</tr>
			<tr>
				<td style="width: 587px;height: 34px;">
					<input type="text" placeholder="请输入文本" maxlength="50" v-model="tabelText.text3" @input="inputTextClick" />
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
	export default{
		name: 'tabel-item',
		props: {
			msg: {
				type: Object,
				default: {}
			},
			indexList: {
				type: Array,
				default: [0,0]
			}
		},
		data(){
			return{
				tabelText: {
					text1: '', text2: '', text3: '', id: ''
				}
			}
		},
		mounted() {
			this.tabelText.id = this.msg.id;
			this.tabelText.text1 = this.msg.comment[0];
			this.tabelText.text2 = this.msg.comment[1];
			this.tabelText.text3 = this.msg.comment[2];
		},
		methods:{
			//填写数据
			inputTextClick() {
				this.$emit('inputEvent', {data: this.tabelText, index: this.indexList});
			},
			getStatuText(num) {
				let str = '优'
				switch (num){
					case 1:
						str = '优';
						break;
					case 2:
						str = '良';
						break;
					case 3:
						str = '中';
						break;
					case 4:
						str = '差';
						break;
					default:
						break;
				}
				return str;
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.table-contents{margin-bottom: 18px;}
	table{border-spacing: 0;border-collapse: collapse;
		td{text-align: center;font-size: 12px;color: #242D33;font-weight: bold;border: 1px solid #CEDAE0;
			span{color: #6C7880;font-weight: 400;}
			input{width: 100%;text-align: left;padding: 0 12px;box-sizing: border-box;}
		}
	}
	::v-deep(.el-table){width: 709px;}
</style>