<template>
	<div class="comment-set-main">
		<div class="data-content">
			<p class="title-text">数转评语设置</p>
			<div class="top-btn-content">
				<el-tabs v-model="activeName" @tab-click="funClick">
					<el-tab-pane :label="item.title" :name="item.id.toString()" v-for="(item, index) in topBtnData" :key="index"></el-tab-pane>
				</el-tabs>				
			</div>
			<div class="fun-btn-content flex flex-col-center">
				<div :class="['item-btn', btnIndex == index?'item-btn-y':'item-btn-n']" v-for="(item, index) in funBtn" :key="index" @click="itemBtnClick(item, index)">{{item.title+'（'+item.value+'分）'}}</div>
			</div>
			<div class="tabel-content" v-loading="loading">
				<div class="tabel-list-content" v-for="(item, index) in allData" :key="index">
					<p class="list-title">{{item.title}}</p>
					<div class=" flex flex-col-center flex-wrap flex-row-between">
						<tabel-item v-for="(items, indexs) in item.comment" :key="items.id" :msg="items" :indexList="[index, indexs]" @inputEvent="inputClick"></tabel-item>
					</div>
				</div>
			</div>
			<button class="save-btn" @click="saveClick">保存</button>
		</div>
	</div>
</template>

<script>
	import tabelItem from "./components/tabel-item.vue"
	export default{
		components: {
			tabelItem
		},
		data(){
			return{
				topBtnData: [],//按钮数据
				funBtn: [],//功能按钮数据
				activeName: '',//顶部按钮下标内容
				btnIndex: 0,//按钮下标
				subData: {industry_type: '', target_id: ''},//要发送的数据
				allData: [],//所有列表数据
				loading: false,//是否正在加载
				saveList: [],//要提交的数据
			}
		},
		mounted() {
			this.initData();
		},
		methods:{
			initData() {
				this.loading = true;
				this.$api.commentTargetList().then(res=>{
					this.topBtnData = res.data;
					this.activeName = res.data[0].id+'';
					this.funBtn = res.data[0].target;
					this.subData.industry_type = res.data[0].id;
					this.subData.target_id = res.data[0].target[0].id;
					this.getSetList();
				}).catch(err=>{
					this.loading = false;
				})
			},
			//保存
			saveClick() {
				let list = [];
				for(let i = 0; i < this.saveList.length; i++) {
					for(let j = 0; j < this.saveList[i].length; j++) {
						list.push(this.saveList[i][j]);
					}
				}
				this.$api.commentSet({comment: list}).then(res=>{
					this.$message({type: 'success', message: '保存成功！'});
					this.getSetList();
				})
			},
			//输入返回
			inputClick(e) {
				this.saveList[e.index[0]][e.index[1]].id = e.data.id;
				this.saveList[e.index[0]][e.index[1]].comment[0] = e.data.text1;
				this.saveList[e.index[0]][e.index[1]].comment[1] = e.data.text2;
				this.saveList[e.index[0]][e.index[1]].comment[2] = e.data.text3;
			},
			//评语设置列表
			getSetList() {
				this.loading = true;
				this.$api.commentSetList(this.subData).then(res=>{
					this.loading = false;
					this.allData = res.data;
					this.saveList = [];
					for (let i = 0; i <  this.allData.length; i++) {
						let itemList =  [
							{id: this.allData[i].comment[0].id, comment: [this.allData[i].comment[0].comment[0], this.allData[i].comment[0].comment[1], this.allData[i].comment[0].comment[2]]}, 
							{id: this.allData[i].comment[1].id, comment: [this.allData[i].comment[1].comment[0], this.allData[i].comment[1].comment[1], this.allData[i].comment[1].comment[2] ]}, 
							{id: this.allData[i].comment[2].id, comment: [this.allData[i].comment[2].comment[0], this.allData[i].comment[2].comment[1], this.allData[i].comment[2].comment[2] ]}, 
							{id: this.allData[i].comment[3].id, comment: [this.allData[i].comment[3].comment[0], this.allData[i].comment[3].comment[1], this.allData[i].comment[3].comment[2] ]} ,
						];
						this.saveList.push(itemList);
					}
				}).catch(err=>{
					this.loading = false;
				})
			},
			funClick(e, item) {
				this.btnIndex = 0;
				this.subData.industry_type = Number(e.name);
				for (let i = 0; i < this.topBtnData.length; i++) {
					if (this.topBtnData[i].id == e.name) {
						this.funBtn = this.topBtnData[i].target;
						this.subData.target_id = this.topBtnData[i].target[0].id;
						this.getSetList();
						return;
					}
				}
			},
			//二级按钮事件
			itemBtnClick(e, index) {
				this.btnIndex = index;
				this.subData.target_id = this.funBtn[index].id;
				this.getSetList();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.comment-set-main{padding: 18px;width:100%; height: 100%;box-sizing: border-box;  
		.data-content{background-color: #fff;padding: 30px 20px;width:100%; min-height: 100%;box-sizing: border-box;
			.title-text{font-size: 18px;color: #242D33;position: relative;margin-left: 12px;
				&:before{position: absolute;content: '';left: -30px;top: 4px;width: 4px;height: 20px;background: #3984F5;}
			}
			.top-btn-content{margin-top: 20px;}
			.fun-btn-content{margin-top: 11px;
				.item-btn{height: 34px;border-radius: 4px;font-size: 14px;line-height: 34px;padding: 0 20px;margin-right: 18px;
					&:hover{cursor: pointer;opacity: .8;}
				}
				.item-btn-y{background: #3984F5;color:#fff;}
				.item-btn-n{background: #EEF1F3;color:#6C7880;}
			}
			.tabel-content{
				.tabel-list-content{margin: 27px 0 4px 0;
					.list-title{font-size: 14px;font-weight: bold;color: #242D33;margin: 0 0 22px 20px;position: relative;
						&:before{position: absolute;content: '';left: -10px;top:4px; height: 14px;width: 4px;background: #3984F5;}
					}
				}
			}
			.save-btn{width: 120px;height: 40px;background: #3984F5;border-radius: 4px;font-size: 12px;color: #fff;line-height: 40px;text-align: center;margin-top: 22px;}
		}
	}
	::v-deep .el-tabs__nav-scroll{
		&::after{height: 1px;}
	}
	
</style>